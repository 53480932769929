import { OneTopRequest, OneTopResponse } from '@ali/tbm-app';
import { from as tbmFrom } from '@ali/tbm-platform';
import { sendOther } from '@ali/tbm-log';
import { armsReport } from '@ali/tbm-monitor';
import { OneTopPlugin } from './onetop-plugin';

const WPO_WHITELIST = ['getUserSession', 'getUserSimple'];
const RESPONSE_TYPE = {
  ERROR: -1,
  SUCCESS: 0,
  TOKEN_EXPIRED: 1,
  SESSION_EXPIRED: 2,
};

const platformName = tbmFrom.name;

// 过滤接口特定返回
const IGNORE_API_RESPONSE_RET = [
  // 服务端降级
  'FAIL_BIZ_CDN_PREPLAN',
];

export class OneTopPluginLog extends OneTopPlugin {
  private startTimestamp = 0;
  private req;

  constructor() {
    super();
    this.pluginName = 'OneTopPluginLog';
    this.startTimestamp = 0;
    this.req = null;
    this.getUA = this.getUA.bind(this);
  }

  getUA() {
    // 获取阿里App标识
    const aliAppName = (/AliApp\(([^\/]+)\/([\d\.\_]+)\)/gi.exec(
      navigator.userAgent
    ) || [])[1];
    return aliAppName || platformName || 'unknown';
  }

  beforeRequest(req: OneTopRequest): OneTopRequest {
    const { requestOptions, cacheResponse } = req;
    const {
      api,
      data = {} as any,
      ignoreNoLoginError,
      ignoreBizError,
    } = requestOptions;
    if (api && WPO_WHITELIST.some(i => api.indexOf(i) > -1)) {
      return req;
    }
    this.startTimestamp = Date.now();

    try {
      performance?.mark?.('tbm-onetop-start-' + api);
    } catch {
      /* empty */
    }

    this.req = {
      api,
      cacheResponse,
      param: data,
      ua: this.getUA().toLowerCase(),
      platform: data.platform?.toString().toLowerCase(),
      ignoreBizError,
      ignoreNoLoginError,
    };

    return req;
  }

  afterResponse(res: OneTopResponse): OneTopResponse {
    if (!this.req) {
      return res;
    }

    const { ret, retType, data = {} as any, traceId = '' } = res;
    const { returnCode = '', returnMessage = '' } = data;
    const {
      cacheResponse,
      api,
      platform,
      ua,
      param,
      ignoreNoLoginError,
      ignoreBizError,
    } = this.req;
    // arms上报倒序展示，便于阅读
    let apiNameArr = api.split('.').reverse();
    // CMS接口名称上报细化，否则无法区分页面
    if (apiNameArr[0] === 'get' && param && param.patternName) {
      apiNameArr.unshift(param.patternName);
    }
    const name = `${cacheResponse ? '(c)' : ''}${apiNameArr.join('.')}`;
    const detail = (ret && ret[0]) || 'NO_RET::未知调用';

    try {
      performance?.mark?.('tbm-onetop-stop-' + api);
      performance?.measure?.(
        api + '(onetop)',
        'tbm-onetop-start-' + api,
        'tbm-onetop-stop-' + api
      );
    } catch {
      /* empty */
    }
    const msg = `[${platform}-${ua}] ${detail}`;
    const __param = JSON.stringify({
      traceId,
      request: {
        ...param,
      },
      response: {
        returnCode,
        returnMessage,
      },
    });
    let isSuccess =
      retType === RESPONSE_TYPE.SUCCESS ||
      (retType === RESPONSE_TYPE.SESSION_EXPIRED && ignoreNoLoginError);
    // 服务端异常也是异常
    // 服务端返回尚未一致性，只针对returnCode存在的时候才进行额外判断
    if (returnCode && returnCode !== '0' && !ignoreBizError) {
      isSuccess = false;
    }

    const perf = Date.now() - this.startTimestamp;

    let isReportLog = true;
    IGNORE_API_RESPONSE_RET.forEach((r: string) => {
      if (detail?.indexOf(r) > -1) isReportLog = false;
    });

    if (isReportLog) {
      // mtop超时20s，则自定义均值上报，避免干扰正常数据
      if (perf >= 20 * 1000) {
        armsReport('avg', `${name}_timeout`, perf);
      } else {
        armsReport('api', name, isSuccess, perf, msg, __param);
      }
    }

    // 埋点上报
    sendOther('/dianying.fe.mtop_get', {
      api,
      success: isSuccess,
      cost: perf,
      ret: detail,
      params: __param,
    });

    return res;
  }
}
