import { OneTopRequest, OneTopResponse } from '@ali/tbm-app';
import { OneTopPlugin } from './onetop-plugin';
import { OneTopPluginCache } from './onetop-plugin-cache';
import { OneTopPluginComplement } from './onetop-plugin-complement';
import { OneTopPluginGetUserSimpleMapToGetUserSession } from './onetop-plugin-getusersimple';
import { OneTopPluginLog } from './onetop-plugin-log';
import { OneTopPluginForceUseH5RequestWhenBizpassH5mtopUrlParamsIsPassed } from './onetop-plugin-mtop-online';
import { OneTopPluginPrefetch } from './onetop-plugin-prefetch';

export const SIMPLE_PLUGINS = [
  OneTopPluginComplement,
  OneTopPluginForceUseH5RequestWhenBizpassH5mtopUrlParamsIsPassed,
];

export const DEFAULT_PLUGINS = [
  OneTopPluginComplement,
  OneTopPluginCache,
  OneTopPluginGetUserSimpleMapToGetUserSession,
  OneTopPluginForceUseH5RequestWhenBizpassH5mtopUrlParamsIsPassed,
  OneTopPluginLog,
  OneTopPluginPrefetch,
];

/**
 * resolvePlugins will create instances of each plugin Object and
 * provide two process `beforeRequestProcess` and `afterResponseProcess`
 */
export function resolvePlugins(plugins: (typeof OneTopPlugin)[] = []) {
  if (!plugins.length) {
    return {};
  }

  const pluginInstances = plugins.map(plugin => new plugin());

  const before = (req: OneTopRequest, plugin: OneTopPlugin) =>
    plugin.beforeRequest(req);
  const after = (res: OneTopResponse, plugin: OneTopPlugin) =>
    plugin.afterResponse(res);

  const beforeRequestProcess = (req: OneTopRequest): OneTopRequest =>
    pluginInstances.reduce(before, req);
  const afterResponseProcess = (res: OneTopResponse): OneTopResponse =>
    pluginInstances.reverse().reduce(after, res);

  return {
    beforeRequestProcess,
    afterResponseProcess,
    name: pluginInstances.map(plugin => plugin.name),
    pluginInstances,
  };
}
