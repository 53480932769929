import { OneTopResponse } from '@ali/tbm-app';

class GlobalState {
  constructor() {
    if (!window.__pre_request_map__) {
      window.__pre_request_map__ = new Map<string, Promise<OneTopResponse>>();
    }
  }

  getPreload(key) {
    return window.__pre_request_map__.get(key);
  }

  hasPreload(key) {
    if (key) {
      return window.__pre_request_map__.has(key);
    }

    return false;
  }

  setPreload(key, preload) {
    if (key && preload) {
      window.__pre_request_map__.set(key, preload);
    }
  }

  deletePreload(key) {
    window.__pre_request_map__.delete(key);
  }
}

export const preloadState = new GlobalState();
