import {
  IMtopResponse,
  MTOP_RESPONSE_TYPE,
  OneTopCallback,
  OneTopRequest,
  OneTopResponse,
} from '@ali/tbm-app';

export const DEFAULT_OPTIONS: OneTopRequest = {
  requestOptions: {
    api: '',
    ecode: 0,
    data: {},
    v: undefined,
    needBindingPage: false,
  },
  prefetchKey: '',
  expire_time: 0,
  H5Request: false,
  WindVaneRequest: false,
  bridgeRequest: false,
  ignoreNoLoginError: false,
  ignoreBizError: false,
  appendPlugins: [],
};

export const handleResponse = (
  res: IMtopResponse,
  onSuccess: OneTopCallback,
  onFail: OneTopCallback
) => {
  const output = (res || {}) as OneTopResponse;
  const ret = output.ret || '';

  // 通常ret是这种形式的数组：["SUCCESS::调用成功"]，但一些不标准的mtop可能直接返回string，所以要分别处理
  if (typeof ret === 'string') {
    output.code = ret;
    output.message = '';
  } else if (Array.isArray(ret)) {
    const retArray = ret[0]?.split('::') || [];
    output.code = (retArray[0] || '').trim();
    output.message = (retArray[1] || '').trim();
  }

  // 补偿retType，retType是lib-mtop.js补偿的，有可能丢失
  // 参考文档：https://aliyuque.antfin.com/mtbsdkdocs/mtopjssdkdocs/kmv1cn
  if (typeof output.retType === 'undefined') {
    if (ret.indexOf('SUCCESS') > -1) {
      output.retType = MTOP_RESPONSE_TYPE.SUCCESS;
    } else {
      output.retType = MTOP_RESPONSE_TYPE.ERROR;
    }
  }

  // 补偿session过期case，lip-mtop.js只返回MTOP_RESPONSE_TYPE.ERROR通用error
  if (output.code?.indexOf('FAIL_SYS_SESSION_EXPIRED') > -1) {
    output.retType = MTOP_RESPONSE_TYPE.SESSION_EXPIRED;
  }

  if (output.retType === MTOP_RESPONSE_TYPE.SUCCESS) {
    onSuccess && onSuccess(output);
    return;
  }

  onFail && onFail(output);
};

export function ready(callback) {
  if (window.AlipayJSBridge) {
    callback && callback();
  } else {
    document.addEventListener('AlipayJSBridgeReady', callback, false);
  }
}

export * from './downgrade';
export * from './generateUniqueKey';
