import Storage from '@ali/tbm-store';
import { OneTopRequest, OneTopResponse } from '@ali/tbm-app';
import { OneTopPlugin } from './onetop-plugin';

export class OneTopPluginPrefetch extends OneTopPlugin {
  isPreFetch: boolean;
  store: any;
  cacheKey: string;
  cacheRes: any;
  whiteList: string[];

  constructor() {
    super();
    this.isPreFetch = false;
    this.cacheRes = undefined;
    this.pluginName = 'OneTopPluginPrefetch';
    this.store = new Storage({
      expireTime: 120000,
    });
    this.whiteList = ['mtop.film.mtopmarketingapi.cacpaymentsolution'];
  }

  setPrefetchData(key, value) {
    key && value && this.store.setSync(key, value);
  }

  belongToWhiteList(api) {
    return (
      this.whiteList.findIndex(prefetchApi => {
        return prefetchApi === api;
      }) > -1
    );
  }

  beforeRequest(req: OneTopRequest): OneTopRequest {
    const { requestOptions, isPreFetch } = req;
    this.cacheKey = requestOptions.api;
    if (isPreFetch) {
      this.isPreFetch = true;
    } else if (this.belongToWhiteList(this.cacheKey)) {
      // 白名单内的api无isPreFetch标记，走检查缓存逻辑
      this.cacheRes = this.store.getSync(this.cacheKey);
      // 获取到缓存值以后删除
      this.store.removeSync(this.cacheKey);
      if (this.cacheRes) {
        req.cacheResponse = this.cacheRes;
      }
    }
    return req;
  }

  afterResponse(res: OneTopResponse): OneTopResponse {
    if (this.isPreFetch && res?.data?.returnCode + '' === '0') {
      this.setPrefetchData(this.cacheKey, res);
    }
    return res;
  }
}
