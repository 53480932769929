import { OneTopRequest, OneTopResponse } from '@ali/tbm-app';

export class OneTopPlugin {
  protected pluginName = 'OneTopPlugin';

  get name() {
    return this.pluginName || this.constructor.name;
  }

  /**
   * beforeRequest 会在请求方法调用前被触发
   * 请求数据在被发送前最后一次修改的机会
   * 可以在这里改变数据接口类型，或是触发其他操作
   * 整个操作是同步的，修改后的数据通过 return 返回给 OneTop
   */
  beforeRequest(req: OneTopRequest): OneTopRequest {
    return req;
  }

  /**
   * afterResponse 会在请求返回数据后触发
   * 可以对请求返回的 Response 进行修改
   * 整个操作是同步的，修改后的数据通过 return 返回给 OneTop
   */
  afterResponse(response: OneTopResponse): OneTopResponse {
    return response;
  }
}
