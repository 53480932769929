import { sendOther } from '@ali/tbm-log';
import { DowngradeOptions } from '@ali/tbm-app';

const SUCCESS_RET = 'SUCCESS::调用成功';
const ERROR_RET = 'ERROR::调用失败';
const DEFAULT_DOWNGRADE_RESPONSE = {
  v: '',
  api: '',
  data: {},
  ret: ERROR_RET,
  isDowngrade: false,
};

/**
 * CMS接口获取容灾文件访问地址
 * 整体文件名生成规则：页面标识_页面版本_bak_[城市ID]_[渠道]_[接口版本]_[业务ID]_[业务参数].json
 * []中的标识可选，不同的业务场景传参不同
 *
 * 本次详情页容灾文件名形如：tpp_show_detail_1.0_bak_101_3.0_1000.json。
 * https://yuque.antfin.com/damai/vlgzhu/wd0li1#Lcc1V
 */

export const getCmsDowngradeUrl = ({
  patternName,
  patternVersion,
  patternSuffix,
}: any) => {
  const domain = `//oss.taopiaopiao.com/`;
  let path: any = [patternName, patternVersion, 'bak', patternSuffix].filter(
    item => !!item
  );
  path = path?.length > 0 ? path?.join('_') : '';
  return `${domain}${path}.json`;
};

/**
 * 非CMS接口容灾地址
 * DEMO: https://taobaodianying.alicdn.com/mtop/mtop.film.mtopshowapi.getshowsbycitycode/4.0/cdn_110100_bak.json
 */
export const getMtopDowngradeUrl = ({
  mtop = '',
  mtopVersion = '',
  cdnFields = [],
}) => {
  const domain = `//taobaodianying.alicdn.com/mtop`;
  let prefix: any = [domain, mtop.toLowerCase(), mtopVersion];
  cdnFields.unshift('cdn');
  cdnFields.push('bak');
  const cdnName = cdnFields.join('_');
  prefix = prefix?.length > 0 ? prefix?.join('/') : '';

  return `${prefix}/${cdnName}.json`;
};

/**
 * 容灾配置
 * - 预发开发配置启用参数：bizpass_oss 与本地小程序开关联动
 */
export const getFallbackSwitchConfig = () => {
  const cdnUuid = /bizpass_oss=pre/.test(window.location.search)
    ? 'a454b6c0018c4ef99fda3bec3ddd8914'
    : '9ce6587c749a424b82ed085f1db9b864';

  return new Promise(resolve => {
    fetch(`//kuai-cdn.taopiaopiao.com/cfg/${cdnUuid}.json`)
      .then(async res => resolve(await res.json()))
      .catch(() => resolve({}));
  });
};

const getDowngradeCdnData = (isCms: boolean, options = {}, trackData = {}) => {
  const api = isCms
    ? getCmsDowngradeUrl(options)
    : getMtopDowngradeUrl(options);

  sendOther('/dianying.fe.downgrade', { ...trackData, cdn: api });

  return fetch(api)
    .then(async res => {
      const data = await res.json();

      console.log(
        `%c[容灾降级成功]`,
        'background: green; color: white; padding: 2px;',
        { cdn: api, ...data }
      );

      return {
        ...DEFAULT_DOWNGRADE_RESPONSE,
        api,
        data,
        ret: SUCCESS_RET,
        isDowngrade: true,
      };
    })
    .catch(() => {
      return { ...DEFAULT_DOWNGRADE_RESPONSE, api };
    });
};

/**
 * 处理CMS降级，满足一条即走降级：
 *  - 1.前端开关
 *  - 2.接口报错
 *  - 3.楼层数据为空
 *
 * @see https://yuque.antfin-inc.com/damai/vlgzhu/qgh6ll#aXfWB
 */
export const processDowngrade = (
  mtopRes,
  configRes,
  downgradeOptions: DowngradeOptions
) => {
  const {
    reqParam = {},
    handleCDN = true,
    handleEmpty = true,
    handleRet = true,
  } = downgradeOptions || {};
  const { retType, data = {} } = mtopRes || {};

  return new Promise<any>(resolve => {
    const isCms = !!(reqParam.patternName && reqParam.patternVersion);

    // 读取降级开关
    const openDowngrade = (configRes?.patternNames || []).some(item => {
      const { on, patternName, patternVersion } = item || {};
      const comparePatternName = isCms ? reqParam?.patternName : reqParam?.mtop;
      const comparePatternVersion = isCms
        ? reqParam.patternVersion
        : reqParam.mtopVersion;

      return (
        comparePatternName &&
        comparePatternVersion &&
        on === '1' &&
        patternName?.toLowerCase() === comparePatternName?.toLowerCase() &&
        patternVersion?.toLowerCase() === comparePatternVersion?.toLowerCase()
      );
    });

    // 判断CMS楼层数据
    let layersLen = 0;
    let downgradeFlag = false;

    if (isCms) {
      const cmsFloorKeys = reqParam?.layersFieldName
        ? [reqParam?.layersFieldName]
        : ['nodes', 'layers'];

      cmsFloorKeys.forEach(key => {
        const len = (data[key] || []).length;
        len && (layersLen = len);
      });

      // 业务控制是否降级
      if (
        (handleCDN && openDowngrade) ||
        (handleRet && retType !== 0) ||
        (handleEmpty && layersLen === 0)
      ) {
        downgradeFlag = true;
      }
    } else if (mtopRes.isError || openDowngrade) {
      downgradeFlag = true;
    }

    if (downgradeFlag) {
      const trackData = isCms
        ? { on: openDowngrade ? 1 : 0, retType: retType, layersLen: layersLen }
        : {};
      return getDowngradeCdnData(isCms, reqParam, trackData).then(resolve);
    }

    return resolve(mtopRes);
  });
};
