import { OneTopRequest } from '@ali/tbm-app';
import { OneTopPlugin } from './onetop-plugin';

export class OneTopPluginForceUseH5RequestWhenBizpassH5mtopUrlParamsIsPassed extends OneTopPlugin {
  static isMatch() {
    if (location.href) {
      return location.href.indexOf('bizpass_h5mtop=YES') > -1;
    }
    return false;
  }

  constructor() {
    super();
    this.pluginName =
      'OneTopPluginForceUseH5RequestWhenBizpassH5mtopUrlParamsIsPassed';
  }

  beforeRequest(req: OneTopRequest): OneTopRequest {
    if (
      !OneTopPluginForceUseH5RequestWhenBizpassH5mtopUrlParamsIsPassed.isMatch()
    ) {
      return req;
    }
    const { requestOptions } = req;
    console.info(`[OneTopPlugin] Use H5 request when bizpass_h5mtop=YES`);
    // @ts-ignore
    // TODO: add to types file
    requestOptions.useH5 = true;
    return req;
  }
}
