import md5 from 'md5';
import { OneTopRequest } from '@ali/tbm-app';

function sort(input) {
  if (typeof input === 'object' && input !== null && !Array.isArray(input)) {
    const sorted = {};
    // 不要修改这里的sonarqube，可以ignore掉
    Object.keys(input)
      .sort()
      .forEach(key => {
        sorted[key] = sort(input[key]);
      });

    return sorted;
  }

  return input;
}

export function generateUniqueKey(req: OneTopRequest): string {
  try {
    return md5(JSON.stringify(sort(req)));

    // eslint-disable-next-line @ali/no-unused-vars
  } catch (ex) {
    // 发生异常返回空字符串，接口将不会preload，照常走实时请求
    return '';
  }
}
