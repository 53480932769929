import {
  Fetcher,
  IMtopRequest,
  IOneTopCall,
  IOneTopOptions,
  OneTopRequest,
  OneTopCallback,
} from '@ali/tbm-app';
import { deepmerge } from '@ali/tbm-util';
import { DEFAULT_OPTIONS, generateUniqueKey, handleResponse } from './utils';
import { resolvePlugins } from './plugins';
import { OneTopPlugin } from './plugins/onetop-plugin';
import { preloadState } from './state';

export class OneTopBase {
  public dict: Record<string, IMtopRequest> = {};
  public plugins: (typeof OneTopPlugin)[];

  private fetcher: Fetcher;
  private options: IOneTopOptions;

  constructor(options?: IOneTopOptions) {
    this.options = options;
    this.collect(this.options?.api || {});
    this.plugins = this.options?.plugins || [];
    this.setFetcher(this.options?.fetcher);
  }

  setFetcher(fetcher: Fetcher) {
    this.fetcher = fetcher;
  }

  setPlugins(plugins) {
    this.plugins = plugins;
  }

  public collect(apis = {}) {
    const output = { ...this.dict, ...apis };
    this.dict = output;
    return output;
  }

  /**
   * 处理入参满足标准mtop请求
   */
  normalizeRequestParameters(req: OneTopRequest): OneTopRequest {
    const { requestOptions } = req;
    const {
      api,
      v,
      type,
      method,
      ecode,
      isSec,
      data,
      headers,
      ext_headers,
      dataType,
      needBindingPage,
      valueType,
      timeout,
      ttid,
      WindVaneRequest,
      dangerouslySetWindvaneParams,
    } = requestOptions;

    // lib-mtop文档参数说明中ecode、isSec必须是Number型，防止业务乱传，此处兼容处理
    const ecodeToNumber = +ecode;
    const isSecToNumber = +isSec;
    // 请求方式，type优先级比method高，method只是做旧代码兼容
    const requestMethod = type || method?.toUpperCase();

    return {
      ...req,
      requestOptions: {
        api,
        v,
        type: requestMethod,
        method: requestMethod,
        data: { ...data, ...{} },
        ecode: ecodeToNumber === 1 ? ecodeToNumber : 0,
        isSec: isSecToNumber === 1 ? isSecToNumber : 0,
        timeout: timeout || 10000,
        headers,
        ext_headers,
        dataType,
        needBindingPage,
        valueType,
        ttid,
        WindVaneRequest,
        dangerouslySetWindvaneParams,
      },
    };
  }

  public err(msg) {
    throw new Error(`[onetop]${msg}`);
  }

  /**
   * 发起mtop请求
   * @param {string|OneTopRequest} api api名
   * @param {OneTopRequest} options api配置
   * @param {OneTopCallback} success 成功回调
   * @param {OneTopCallback} fail 失败回调
   */
  public call: IOneTopCall = (
    api: string | OneTopRequest,
    options?: OneTopRequest,
    success?: OneTopCallback,
    fail?: OneTopCallback
  ) => {
    const isCallback = success || fail;
    let { req, originReq } = this.initializeRequest(api, options);
    const preloadKey = generateUniqueKey(originReq);

    if (preloadState.hasPreload(preloadKey)) {
      const preload = preloadState.getPreload(preloadKey);
      preloadState.deletePreload(preloadKey);

      if (isCallback) {
        return preload?.then(res => success(res)).catch(ex => fail(ex));
      }

      return preload;
    }

    let plugins = [...this.plugins];
    if (req?.appendPlugins?.length) {
      plugins = plugins.concat(req.appendPlugins);
    }

    const { beforeRequestProcess, afterResponseProcess } =
      resolvePlugins(plugins);

    if (beforeRequestProcess) {
      req = beforeRequestProcess(req);

      if (req.cacheResponse) {
        console.log(`${req.requestOptions.api} 命中缓存`);
        if (isCallback) {
          success?.(req.cacheResponse);
          return;
        } else {
          return Promise.resolve(req.cacheResponse);
        }
      }
    }

    const requestMethod = this.getRequestMethod(req);
    if (isCallback) {
      requestMethod(req, res => {
        handleResponse(
          afterResponseProcess ? afterResponseProcess(res) : res,
          success,
          fail
        );
      });
    } else {
      return new Promise<any>((resolve, reject) => {
        requestMethod(req, res => {
          handleResponse(
            afterResponseProcess ? afterResponseProcess(res) : res,
            resolve,
            reject
          );
        });
      });
    }
  };

  private getRequestMethod(request: OneTopRequest) {
    if (typeof this.fetcher === 'undefined' || !this.fetcher.getMethod) {
      this.err('请提供`fetcher`实现');
    }

    return this.fetcher.getMethod(request);
  }

  /** 接口请求数据可能来自配置文件，也可能来自直接传参，需要进行规整初始化，方便调用 */
  private prepareApi(api: string | OneTopRequest, options?: OneTopRequest) {
    let output: OneTopRequest = {};

    if (typeof api === 'string') {
      const config: any = this.dict[api];
      if (!config) {
        this.err(`[${api}] 缺少配置信息`);
      }
      output = deepmerge({}, config, options);
    } else if (typeof api === 'object') {
      output = api;
    } else {
      this.err(`[${api}] 参数类型错误`);
    }

    if (!output?.requestOptions?.api) {
      this.err(`API配置信息错误，请检查配置`);
    }

    return output;
  }

  /** 对api进行参数矫正、补偿 */
  public initializeRequest(
    api: string | OneTopRequest,
    options?: OneTopRequest
  ) {
    const parsedApi = this.prepareApi(api, options);
    return {
      originReq: parsedApi,
      req: this.normalizeRequestParameters(
        deepmerge({}, DEFAULT_OPTIONS, parsedApi)
      ),
    };
  }
}
