import UA from '@ali/tbm-ua';
import { OneTopRequest, OneTopRequestPayload } from '@ali/tbm-app';
import { getDMChannel } from '@ali/tbm-util';
import { from } from '@ali/tbm-platform';
import { OneTopPlugin } from './onetop-plugin';

/** 补偿基础参数 */
export class OneTopPluginComplement extends OneTopPlugin {
  static createEagleEyeX() {
    return (
      document.cookie.match(
        /(?:(?:^|.*;\s*)_tpp_project_id\s*\=\s*([^;]*).*$)|^.*$/
      )[1] || ''
    );
  }

  constructor() {
    super();
    this.pluginName = 'OneTopPluginComplement';
  }

  beforeRequest(req: OneTopRequest): OneTopRequest {
    const {
      requestOptions,
      expire_time,
      WindVaneRequest,
      ignoreNoLoginError,
      ignoreBizError,
    } = req;

    const payload: OneTopRequestPayload = {
      ...requestOptions,
      expire_time,
      ignoreNoLoginError,
      ignoreBizError,
      ignTbpass: Boolean((UA.isWX || UA.isWXApp) && UA.isIOS) || undefined,
      forceAntiCreep: true,
      AntiCreep: true,
      tb_eagleeyex_scm_project: OneTopPluginComplement.createEagleEyeX(),
    };

    if (WindVaneRequest) {
      payload.WindVaneRequest = true;
    }

    /**
     * WARNING
     * payload中的`H5Request`不能设置为`false`，只能为`true`或空,
     * 否则mtop会报: `UNEXCEPT_REQUEST::错误的请求类型`
     */
    if (this.useH5Request(req)) {
      payload.H5Request = true;
      // mega不同于windvane，不再支持H5Request，windVaneRequest之类的参数，业务参数不调整，通过onetop来添加映射，导到h5实现
      (payload as any).useH5 = true;
    }

    this.removeNilFields(payload);
    payload.data = this.formatData(req);

    return {
      ...req,
      requestOptions: payload,
    };
  }

  private useH5Request(req: OneTopRequest) {
    if (req.H5Request) {
      return true;
    }

    const hostname = window.location.hostname || '';
    // 大麦渠道里 票票域的页面默认不做任何设置会走H5请求, 指定WindVaneRequest为true 则会强制使用native发起mtop请求
    if (
      UA.isDM &&
      /taopiaopiao\.com$/.test(hostname) &&
      !req.H5Request &&
      !req.bridgeRequest &&
      !req.WindVaneRequest
    ) {
      return true;
    }

    return false;
  }

  private formatData(req: OneTopRequest) {
    const data = req?.requestOptions?.data || {};
    this.removeNilFields(data);

    // 补偿电影/演出业务渠道
    if (!data.platform) {
      data.platform = from.platform;
    }
    if (!data.comboChannel) {
      data.comboChannel = from.comboChannel;
    }
    if (!data.dmChannel) {
      data.dmChannel = getDMChannel();
    }

    if (data.args) {
      try {
        data.args = this.setComboChannelForArgs(data);
      } catch (ex) {
        console.error('setComboChannelForArgs error:', ex);
      }
    }

    return data;
  }

  private removeNilFields(input = {}) {
    Object.keys(input).forEach(key => {
      const value = input[key];
      if (value === undefined || value === null || value === '') {
        // eslint-disable-next-line no-param-reassign
        delete input[key];
      }
    });
  }

  private setComboChannelForArgs({ args, comboChannel, dmChannel, platform }) {
    let argsObj: any = {};
    if (typeof args === 'string') {
      argsObj = JSON.parse(args);
    } else if (typeof args === 'object') {
      argsObj = args;
    }

    if (!argsObj.platform) {
      argsObj.platform = platform;
    }

    if (!argsObj.comboChannel) {
      argsObj.comboChannel = comboChannel;
    }

    if (!argsObj.dmChannel) {
      argsObj.dmChannel = dmChannel;
    }

    return JSON.stringify(argsObj);
  }
}
