import UA from '@ali/tbm-ua';
import { Fetcher, OneTopRequest } from '@ali/tbm-app';
import Platform from '@ali/tbm-platform';
import { parseQS } from '@ali/tbm-util';
import { OnetopMock } from '../../mock';
import { OnetopPrefetch } from './prefetch';
import { DowngradeService } from './downgrade';
import { getMtop } from './mtop';

/**
 * H5通用数据获取器
 */
export class DefaultFetcher implements Fetcher {
  private useDowngrade;
  private mockService;
  private prefetchService;
  private downGradeService;

  constructor() {
    const query = parseQS(location.search) || {};
    this.useDowngrade = (query.bizpass_downgrade || '').toLowerCase() === 'yes';
    this.mockService = new OnetopMock({ query });
    this.downGradeService = new DowngradeService(this.useDowngrade);

    if (UA.isPHA || (query.enable_prefetch || '').toLowerCase() === 'yes') {
      this.prefetchService = new OnetopPrefetch();
    }
  }

  getMethod(req: OneTopRequest) {
    const { downgradeOptions, prefetchKey, shouldPrefetchFn, requestOptions } =
      req;
    let method = getMtop();

    // mock数据
    if (this.mockService.isMockOpen(req.requestOptions.api)) {
      method = this.mockService.hookRequest(method);
    }

    // 预请求
    if (prefetchKey && this.prefetchService) {
      method = this.prefetchService.hookRequest(
        method,
        prefetchKey,
        shouldPrefetchFn
      );
    }

    // 容灾降级
    if (!downgradeOptions) return method;

    // downgradeOptions支持传true。设置为true时，downgradeOptions由requestOptions参数组装
    let newDowngradeOptions = downgradeOptions;

    if (typeof newDowngradeOptions === 'boolean' && newDowngradeOptions) {
      const { patternName, patternVersion, args } = requestOptions?.data ?? {};
      const cityIdStr = args?.comboCityId ? `_${args?.comboCityId}` : '';
      const patternSuffix = `${Platform.from.comboChannel}_${patternVersion}${cityIdStr}`;

      console.log('容灾降级，patternSuffix参数值：', patternSuffix);
      newDowngradeOptions = {
        reqParam: {
          patternName,
          patternVersion,
          patternSuffix,
        },
      };
    }

    return this.downGradeService.hookRequest(method, newDowngradeOptions);
  }
}
