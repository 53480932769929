import UA from '@ali/tbm-ua';
import { IOneTopOptions, OneTopRequest } from '@ali/tbm-app';
import { parseQSToLowerCase } from '@ali/tbm-util';
import { OneTopBase } from './base';
import { OneTopPlugin } from './plugins/onetop-plugin';
import { DefaultFetcher } from './fetcher/default';
import { DEFAULT_PLUGINS } from './plugins';
import { generateUniqueKey } from './utils';
import { preloadState } from './state';

/**
 * 影演H5通用数据访问模块
 */
class OneTop extends OneTopBase {
  static DEFAULT_PLUGINS: (typeof OneTopPlugin)[] = DEFAULT_PLUGINS;

  constructor(options: IOneTopOptions = { api: {} }) {
    super(options);
    this.setFetcher(new DefaultFetcher());
    this.setPlugins(OneTop.DEFAULT_PLUGINS);
  }

  /**
   * OneTop默认支持`promise`风格调用，无需再调用`promisify`：
   *   onetop.call(api, options).then(() => {});
   * @deprecated
   */
  promisify() {
    return this;
  }

  /**
   * 设置请求配置
   * @deprecated
   * @param {Object} requestData - 请求参数
   * @param {Object} requestOptions - request配置，比如 v, api, ecode 等
   * @param {Object} apiOptions - api配置，比如 H5Request, expire_time 等
   */
  setOptions(requestData = {}, reqOptions = {}, apiOptions = {}) {
    return {
      requestOptions: {
        ...reqOptions,
        data: requestData,
      },
      ...apiOptions,
    };
  }

  preload(input: OneTopRequest) {
    const key = generateUniqueKey(input);
    if (preloadState.hasPreload(key)) {
      return preloadState.getPreload(key);
    }

    const req = this.call(input);
    preloadState.setPreload(key, req);
    return req;
  }
}

const query = {
  parseQSToLowerCase,
};

const onetop = new OneTop();
export { onetop, OneTopPlugin, query, UA };
export default OneTop;
