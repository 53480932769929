import { mtop } from '@ali/one-bridge';
import { IMtopRequestMethod } from '@ali/tbm-app';

export function getMtop() {
  const method: IMtopRequestMethod = (req, callback) => {
    const request = {
      ...req.requestOptions,
    } as any;

    if (req.H5Request) {
      // one-bridge使用这个参数确定走H5渠道
      request.useH5 = true;
    }

    return mtop(request)
      .then(callback)
      .catch(ex => {
        console.error(ex);
        callback(ex);
      });
  };

  return method;
}
