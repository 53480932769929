import ua from '@ali/tbm-ua';
import { OneTopRequest, OneTopResponse } from '@ali/tbm-app';
import { OneTopPlugin } from './onetop-plugin';

export class OneTopPluginGetUserSimpleMapToGetUserSession extends OneTopPlugin {
  private readonly getUserSimpleApiName = 'mtop.user.getUserSimple';
  private readonly getUserSimpleApiVersion = '1.0';

  static isSwitch() {
    // 目前只在微信小程序中做api map
    return (
      ua.isWXApp ||
      ua.isXHSMiniProgram ||
      ua.isBDminiApp ||
      ua.isBaiduApp ||
      ua.isIQiYiMiniProgram ||
      ua.isKSminiApp
    );
  }

  constructor() {
    super();
    this.pluginName = 'OneTopPluginGetUserSimpleMapToGetUserSession';
  }

  beforeRequest(req: OneTopRequest): OneTopRequest {
    if (!OneTopPluginGetUserSimpleMapToGetUserSession.isSwitch()) {
      return req;
    }
    const { requestOptions } = req;
    console.info(`[OneTopPlugin] map getUserSimple to getUserSession`);
    const { api } = requestOptions;
    if (api === 'mtop.user.getUserSimple') {
      requestOptions.api = 'mtop.film.MtopUserAPI.getUserSession';
      requestOptions.v = '8.2';
    }

    return req;
  }

  afterResponse(response: OneTopResponse): OneTopResponse {
    if (!OneTopPluginGetUserSimpleMapToGetUserSession.isSwitch()) {
      return response;
    }
    const { api, data = {} as any, retType } = response;
    if (api === 'mtop.film.mtopuserapi.getusersession') {
      if (
        data.returnCode === '0' &&
        retType === 0 &&
        data.returnValue &&
        data.returnValue.userId
      ) {
        return {
          api: this.getUserSimpleApiName,
          v: this.getUserSimpleApiVersion,
          retType,
          ret: ['SUCCESS::调用成功'],
          data: { nick: '', userNumId: data.returnValue.userId } as any,
        };
      }
      // 异常情况
      return {
        api: this.getUserSimpleApiName,
        v: this.getUserSimpleApiVersion,
        ret: response.ret,
        retType,
        data,
      };
    }
    return response;
  }
}
