import {
  OneTopRequest,
  IMtopRequestMethod,
  DowngradeOptions,
} from '@ali/tbm-app';

import {
  handleResponse,
  processDowngrade,
  getFallbackSwitchConfig,
} from '../../utils';

/**
 * 容灾降级服务
 */
export class DowngradeService {
  private useDowngrade: boolean;
  private downgradeOptions: DowngradeOptions;
  private oriRequestMethod: IMtopRequestMethod;

  constructor(useDowngrade) {
    this.useDowngrade = useDowngrade;
  }

  public hookRequest(
    request: IMtopRequestMethod,
    downgradeOptions: DowngradeOptions
  ) {
    this.oriRequestMethod = request;
    this.downgradeOptions = downgradeOptions || {};
    return this.agentRequest;
  }

  protected agentRequest: IMtopRequestMethod = (
    req: OneTopRequest,
    callback
  ) => {
    const { reqParam: { pageIndex = 0 } = {} } = this.downgradeOptions || {};

    const promiseArray = [
      new Promise<any>(resolve =>
        this.oriRequestMethod(req, res => {
          handleResponse(res, resolve, output => {
            resolve({ ...output, isError: true });
          });
        })
      ),
    ];

    /**
     * 请求容灾配置
     * - 端侧开启容灾降级 && 非分页数据请求
     */
    if (this.useDowngrade && pageIndex <= 1) {
      promiseArray.push(getFallbackSwitchConfig());
    }

    return Promise.all(promiseArray).then(
      ([mtopRes, configRes] = [] as any) => {
        return processDowngrade(mtopRes, configRes, this.downgradeOptions)
          .then(callback)
          .catch(callback);
      }
    );
  };
}
