import Cookies from 'js-cookie';
import Storage from '@ali/tbm-store';
import { OneTopRequest, OneTopResponse } from '@ali/tbm-app';
import { OneTopPlugin } from './onetop-plugin';
import { generateUniqueKey } from '../utils';

export class OneTopPluginCache extends OneTopPlugin {
  private key: string;
  private cache: any;
  private store: any;

  static COOKIE_NICK_KEY = 'tracknick';
  static MIN = 60000;

  constructor() {
    super();
    this.pluginName = 'OneTopPluginCache';
    this.key = null;
    this.cache = null;
    this.store = null;
    this.parseKey = this.parseKey.bind(this);
    this.setCache = this.setCache.bind(this);
    this.getCache = this.getCache.bind(this);
  }

  parseKey(req: OneTopRequest) {
    const {
      requestOptions: { api = '', ecode = 0 },
    } = req;
    const cacheKey = generateUniqueKey(req);
    if (!cacheKey) {
      return '';
    }

    const keys = [api.toLowerCase(), cacheKey];

    // 需要登录态的接口带上用户信息
    if (+ecode === 1) {
      const nick = Cookies.get(OneTopPluginCache.COOKIE_NICK_KEY);
      if (nick) {
        keys.push(nick);
      }
    }

    return keys.join('-');
  }

  setCache(key, value) {
    key && value && this.store.setSync(key, value);
  }

  getCache(req: OneTopRequest) {
    const {
      requestOptions: { expire_time = 0 },
    } = req;

    if (!expire_time || +expire_time === 0) return null;

    this.store = new Storage({ expireTime: expire_time });
    this.key = this.parseKey(req);
    this.cache = this.store.getSync(this.key);
    return this.cache;
  }

  beforeRequest(req: OneTopRequest): OneTopRequest {
    const cache = this.getCache(req);
    if (cache) {
      req.cacheResponse = cache;
    }

    return req;
  }

  afterResponse(response: OneTopResponse): OneTopResponse {
    const { retType, data } = response;
    if (!this.cache && retType === 0) {
      if ((data as any)?.returnCode * 1 === 0) {
        this.setCache(this.key, response);
      }
    }

    return response;
  }
}
