/* eslint-disable no-param-reassign */

import UA from '@ali/tbm-ua';
import { IMtopRequestMethod, OneTopRequest } from '@ali/tbm-app';
import { sendOther } from '@ali/tbm-log';
import { storeFactory } from '@ali/tbm-store';

/**
 * Mtop数据预请求
 * Tips：如果预请求失败，则重新发起默认请求
 */
export class OnetopPrefetch {
  private oriRequestMethod: IMtopRequestMethod;
  private prefetchKey = '';
  private apStore;
  private shouldPrefetchFn: any;

  constructor() {
    if (UA.isAP) {
      this.apStore = storeFactory({
        type: 'apData',
      });
      // 钱包数据预请求映射表，用于判断只消费一次
      window.apDataPrefetchMap = window.apDataPrefetchMap || {};
    }
  }

  private formatResponse(data) {
    return {
      ...data,
      retType: 0,
    };
  }

  /**
   * 埋点上报
   */
  private sendLog(logkey, logData = {} as any) {
    sendOther(logkey, logData);
    // 往全局变量写入预请求埋点信息，便于性能埋点采集上报，前期先不考虑多prefetchKey场景
    const { status, error, cost, api, key } = logData;
    window.__tpp_prefetch_log = { status, error, cost, api, key };
  }

  /**
   * 比较参数值
   */
  private compareParamValue(a, b, name) {
    // 统一用字符串进行比较，避免：0 vs '0'
    a += '';
    b += '';
    const isEqual = a === b;

    // dmChannel/comboChannel是tbm-onetop为H5应用统一加的，但小程序没有加，所以这里直接pass
    if (/^(dmChannel|comboChannel)$/gi.test(name)) {
      return true;
    }

    // 因经纬度存在精度误差，无法精确判断是否相等，
    // 如：113.0142 vs 113.014174，所以只要两个都存在，就返回true
    if (!isEqual && /^(longitude|latitude)$/gi.test(name) && a && b) {
      return true;
    }
    return isEqual;
  }

  /**
   * 入参校验
   */
  private verifyInParams(cachedParams, realParams = {}) {
    if (!cachedParams) {
      return false;
    }
    return Object.keys(realParams).every(name => {
      const realValue = realParams[name];
      const cachedValue = cachedParams[name];
      let isEqual = this.compareParamValue(realValue, cachedValue, name);
      // JSON串比较
      if (!isEqual && /^{\"/gm.test(realValue)) {
        let realJSON, cachedJSON;
        try {
          realJSON = JSON.parse(realValue);
          cachedJSON = JSON.parse(cachedValue);

          // eslint-disable-next-line @ali/no-unused-vars
        } catch (e) {
          /* empty */
        }
        if (realJSON && cachedJSON) {
          isEqual = Object.keys(realJSON).every(subname =>
            this.compareParamValue(
              realJSON[subname],
              cachedJSON[subname],
              subname
            )
          );
        }
      }

      return isEqual;
    });
  }

  public hookRequest(
    request: IMtopRequestMethod,
    prefetchKey,
    shouldPrefetchFn
  ) {
    this.oriRequestMethod = request;
    this.prefetchKey = prefetchKey || '';
    this.shouldPrefetchFn = shouldPrefetchFn;
    return this.agentRequest;
  }

  protected agentRequest: IMtopRequestMethod = (
    req: OneTopRequest,
    callback
  ) => {
    const { requestOptions } = req;
    const { api, data = {} } = requestOptions;
    const startTime = Date.now();
    let logkey;

    // 处理失败
    const handleFail = (logData?) => {
      if (logkey) {
        this.sendLog(logkey, {
          status: 0,
          error: 'fail',
          api,
          key: this.prefetchKey,
          ...logData,
          cost: Date.now() - startTime,
        });
        console.log(
          '[OnetopPrefetch]: 预请求数据无效，启用浏览器 MTOP 请求！',
          logData
        );
      }
      this.oriRequestMethod(req, callback);
    };

    if (!this.prefetchKey) {
      handleFail({ error: 'no_key' });
      return;
    }

    // 处理返回
    const handleResponse = (
      prefetchData?: any,
      successCallback?: () => void
    ) => {
      let errorType;
      let isValid = !!prefetchData;
      if (!isValid) {
        errorType = 'no_data';
      } else if (this.shouldPrefetchFn) {
        isValid = this.shouldPrefetchFn(prefetchData);
        if (!isValid) {
          errorType = 'invalid_data';
        }
      }

      if (isValid) {
        this.sendLog(logkey, {
          status: 1,
          api,
          key: this.prefetchKey,
          cost: Date.now() - startTime,
        });
        callback(this.formatResponse(prefetchData));
        successCallback && successCallback();
        console.log(`[OnetopPrefetch]: 预请求成功 ${api}`);
      } else {
        handleFail({ error: errorType });
      }
    };

    if (this.apStore && !window.apDataPrefetchMap[this.prefetchKey]) {
      // 钱包共享缓存
      logkey = '/dianying.fe.ap_prefetch_get';
      window.apDataPrefetchMap[this.prefetchKey] = 1;
      this.apStore.get(this.prefetchKey).then(prefetchData => {
        const { request, response, expireTime } = prefetchData || {};
        // 入参校验
        if (this.verifyInParams(request, data)) {
          handleResponse(response, () => {
            // 成功消费之后立即删除，避免手动刷新时仍用缓存数据
            this.apStore.remove(this.prefetchKey);
          });
        } else {
          handleFail({
            error: request ? 'params_unmatch' : 'no_data',
            real: request && data,
            cached: request,
            expire: expireTime,
          });
        }
      });
    } else if (window.pha && window.pha.dataPrefetch) {
      // 手淘PHA
      logkey = '/dianying.fe.pha_prefetch_get';
      window.pha.dataPrefetch.getData(
        { key: this.prefetchKey },
        prefetchData => {
          handleResponse(prefetchData);
        },
        () => {
          handleFail({ error: 'pha_get_fail' });
        }
      );
    } else {
      handleFail();
    }
  };
}
